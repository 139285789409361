/* window */
import React from 'react'
import { FaAngleRight, FaCheck, FaTimes } from 'react-icons/fa';
import ComparisonScripts from '../../components/ComparisonScripts';
import { Experiment, Variant, emitter } from '@marvelapp/react-ab-test';

import Layout from '../../components/Layout';

// eslint-disable-next-line
import leadPage from '../../components/leadPage.css';
// eslint-disable-next-line
import hero from '../../components/hero.css';
// eslint-disable-next-line
import buttons from '../../components/Buttons/buttons.css';

export default class HomepageV2BFocused extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: 0,
    }

    // emitter.defineVariants('exp_movein_journey_1', ['A', 'B']);
  }

  buttonClass = (num) => {
    return `home-features__link-item btn0 active`;
  }
  render() {
    return (
      <Layout nav={false}>
        <div>
          <div className="hero__overlay">
            <div className="container-standard">
              {/* <div className="hero__wrapper"> */}
                <div className="hero__intro" style={{ minHeight: '10rem' }}>
                  <ComparisonScripts options={{
                    serviceType: 'energy',
                    comparisonStyle: 'moveIn',
                    partnerId: '100009',
                    settings: {
                      runExperiments: true,
                      setExperiments: {
                        exp_movein_journey_1: 'B'
                      },
                      amplitudeId: '0a743d268cfa65f0174ab76e8213df8b',
                    },
                  }} />
                  <div id="homeboxComparison" />
                </div>
              {/* </div> */}

            </div>
          </div>
        </div>

        <div className="logos__wrapper">
          <div className="container-standard">
            <div className="logos">
              <div className="logos__logo logo--text-first">Supported<br /> Suppliers:</div>
              <div className="logos__logo"><img src="/images/suppliers/bulb-logo-small-transparent.png" alt="Bulb Energy" /></div>
              <div className="logos__logo"><img src ="/images/suppliers/bt-small.png" alt="PlusNet Broadband" /></div>
              <div className="logos__logo"><img src="/images/suppliers/ovo-small.png" alt="OVO Energy" /></div>
              <div className="logos__logo"><img src="/images/suppliers/talktalk-small.png" alt="Talk Talk Broadband" /></div>
              <div className="logos__logo"><img src="/images/suppliers/edf-small.png" alt="EDF Energy"/></div>
              <div className="logos__logo"><img src="/images/suppliers/sky-small.png" alt="Sky Broadband"/></div>
              <div className="logos__logo logo--text-last">+ Many More</div>
            </div>
          </div>
        </div>

        <div className="angledBack" style={{ position: 'relative'}}>

          <div className="hiw-v2 " id="hiw">
            <div className="container-standard">
              <div className="hiw-v2__items">
                <div className="grid-medium-up">
                  <div className="hiw-v2__item grid-item">
                    <div className="hiw-v2__item-no">1</div>
                    <h4>Add your details</h4>
                    <p>Your details are securely stored so you won't need to enter them again in the future.</p>
                  </div>
                  <div className="hiw-v2__item grid-item">
                    <div className="hiw-v2__item-no">2</div>
                    <h4>Get the best deal for you</h4>
                    {/* <p>Homebox intelligently finds you the best deals from <strong>a wider range of deals than traditional comparison sites.</strong></p> */}
                    <p>Homebox intelligently finds you the best deals from a range of energy suppliers</p>
                    {/*<p><a href="">How do we beat comparison sites? <FaAngleRight /></a></p>*/}
                  </div>
                  <div className="hiw-v2__item grid-item">
                    <div className="hiw-v2__item-no">3</div>
                    <h4>Switch without the hassle</h4>
                    <p>We take the effort out of finding and staying on the best energy deals <span role="img" aria-label="rocket">🚀</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="savings" id="savings">
          <div className="container-standard">
            <div className="grid-medium-up">

              <div className="savings__story">
                <img className="savings__pic" src="/images/customer-alex.png" alt="Alex - a happy Homebox customer" />
                <div className="savings__name">Alexander Bilney</div>
                <div className="savings__quote">"I saved money on both my <strong>electricity and internet</strong> in no more than 10-15 mins. <strong>I thoroughly recommend Homebox.</strong>"</div>
                <div className="savings__tp">
                  <a href="https://www.trustpilot.com/reviews/5a01e6a6ee693f08c8202a72" rel="noopener noreferrer" target="_blank">
                    <img src="/images/trustpilot-stars-1.png" alt="Homebox 5 star reviews" />
                  </a>
                </div>
              </div>

              <div className="savings__story">
                <img className="savings__pic" src="/images/testimonial-paul.jpg" alt="Alex a happy Homebox customer" />
                <div className="savings__name">Paul Haydon</div>
                <div className="savings__quote">"Brilliant, very easy and straightforward to set up. Got a few emails over the months and clicked to save, haven't had to do anything and know I'm always on a decent deal!"</div>
                <div className="savings__tp">
                  <a href="https://www.trustpilot.com/review/homebox.io" rel="noopener noreferrer" target="_blank">
                    <img src="/images/trustpilot-stars-1.png" alt="Homebox 5 star reviews" />
                  </a>
                </div>
              </div>

            </div>
            {/* <div style={{ textAlign: 'center', marginTop: '3rem' }}>
              <a href="https://www.trustpilot.com/review/homebox.io" target="_blank" rel="noopener noreferrer" className="button button--secondary">
                More Reviews &nbsp;<FaAngleRight />
              </a>
            </div> */}
          </div>
        </div>
      </Layout>
    )
  }
}
