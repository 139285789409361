import React from 'react'
import HomepageV2BFocused from '../../spaces/Homepage/HomepageV2BFocused';

export default class SiteIndex extends React.Component {
  render() {
    return (
      <HomepageV2BFocused />
    )
  }
}
